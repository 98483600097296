import { List } from '@mantine/core';

export const StaticPasswordHelp = () => {
  return (
    <List size="sm" mt={'sm'}>
      <List.Item>12 caractères minimum</List.Item>
      <List.Item>1 lettre majuscule minimum</List.Item>
      <List.Item>1 lettre minuscule minimum</List.Item>
      <List.Item>1 chiffre minimum</List.Item>
      <List.Item>1 caractère spécial minimum (@, $, !, %, *, ?, &)</List.Item>
    </List>
  );
};
